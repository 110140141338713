<template>
    <div class=container>
        <div class=table>
<h4> Table of Viewing and Illumination Directions </h4>
<p>
Each row in the table
gives the measurement position number
(1-205) followed by:
</p><ul>
<li>theta_v (polar angle of the viewing direction),</li>
<li>phi_v  (azimuthal angle of the viewing direction),</li>
<li> theta_i  (polar angle of the illumination direction),</li> and
<li>phi_i  (the azimuthal angle of the illumination
direction).</li>
</ul>
<hr>
<pre>No.    theta_v     phi_v      theta_i     phi_i
-------------------------------------------------
1     1.389566   -1.970402   1.374447   -1.570796
2     0.981748    0.000000   1.374447    0.000000
3     1.463021   -2.763231   1.370707   -2.376765
4     1.308097   -1.163336   1.370707   -0.764828
5     0.987375   -0.134267   1.370707   -0.041141
6     1.215585   -0.811489   1.360401   -0.430767
7     1.007874   -0.282959   1.360401   -0.094629
8     1.141555   -0.627520   1.357866   -0.281035
9     1.050598   -0.417031   1.357866   -0.153484
10    1.209430   -1.992270   1.178098   -1.570796
11    0.785399    0.000000   1.178098    0.000000
12    1.272499   -2.496589   1.154239   -2.096192
13    1.099841   -1.476736   1.154239   -1.045400
14    0.774850   -0.219877   1.154239   -0.094629
15    1.348016   -2.815465   1.150262   -2.456873
16    1.008179   -1.101321   1.150262   -0.684719
17    0.809816   -0.439945   1.150262   -0.201358
18    1.421060   -3.015733   1.130287   -2.738536
19    0.877978   -0.762419   1.130287   -0.403056
20    1.374447    3.141593   0.981748    3.141593
21    1.031794   -2.032979   0.981748   -1.570796
22    0.589049    0.000000   0.981748    0.000000
23    1.337086   -3.090099   0.955317   -2.988109
24    1.101194   -2.432726   0.955317   -2.005315
25    0.909333   -1.623802   0.955317   -1.136277
26    0.582273   -0.334581   0.955317   -0.153484
27    1.128806   -2.736898   0.876815   -2.376909
28    0.716526   -1.270836   0.876815   -0.764684
29    1.217332   -2.966775   0.876815   -2.738536
30    0.581425   -0.799895   0.876815   -0.403056
31    1.178097    3.141593   0.785398    3.141593
32    0.858886   -2.100699   0.785398   -1.570796
33    0.392699    0.000000   0.785398    0.000000
34    1.038285   -3.013835   0.662145   -2.860557
35    0.866142   -2.496546   0.662145   -2.005315
36    0.627477   -1.794991   0.662145   -1.136277
37    0.318893   -0.713993   0.662145   -0.281035
38    0.929649   -2.801295   0.615479   -2.456873
39    0.426124   -1.398445   0.615479   -0.684719
40    0.981747    3.141593   0.589048    3.141593
41    0.694806   -2.211453   0.589048   -1.570796
42    0.196349    0.000000   0.589048    0.000000
43    0.839669   -2.948852   0.472749   -2.710826
44    0.733813   -2.628785   0.472749   -2.096192
45    0.445325   -1.955310   0.472749   -1.045400
46    0.213063   -1.410017   0.472749   -0.430767
47    0.785398    3.141593   0.392699    3.141593
48    0.548028   -2.395741   0.392699   -1.570796
49    0.000001    3.141593   0.392699    0.000000
50    0.625671   -2.857075   0.284924   -2.376765
51    0.274014   -2.335623   0.284924   -0.764828
52    0.589048    3.141593   0.196349    3.141593
53    0.436759   -2.701422   0.196349   -1.570796
54    0.196350    3.141593   0.196349    0.000000
55    0.392699    3.141593   0.000000    0.000000
56    1.432405   -2.365895   1.374447   -1.570796
57    0.589049    0.000000   1.374447    0.000000
58    1.285872   -1.570796   1.370707   -0.764828
59    0.610885   -0.293831   1.370707   -0.041141
60    1.122109   -1.226629   1.360401   -0.430767
61    0.680547   -0.578211   1.360401   -0.094629
62    0.979211   -1.038908   1.357866   -0.281035
63    0.785398   -0.785398   1.357866   -0.153484
64    1.296783   -2.395740   1.178098   -1.570796
65    0.392700    0.000000   1.178098    0.000000
66    1.431921   -2.864769   1.154239   -2.096192
67    1.122109   -1.914964   1.154239   -1.045400
68    0.413656   -0.508053   1.154239   -0.094629
69    0.955317   -1.570796   1.150262   -0.684719
70    0.523599   -0.886076   1.150262   -0.201358
71    0.717004   -1.267733   1.130287   -0.403056
72    1.167071   -2.447955   0.981748   -1.570796
73    0.196350    0.000000   0.981748    0.000000
74    1.308997   -2.790714   0.955317   -2.005315
75    0.979211   -2.102685   0.955317   -1.136277
76    0.261800   -0.938882   0.955317   -0.153484
77    1.419463   -3.014362   0.876815   -2.376909
78    0.717004   -1.873860   0.876815   -0.764684
79    0.440510   -1.570796   0.876815   -0.403056
80    1.047198   -2.526113   0.785398   -1.570796
81    0.000000    0.000000   0.785398    0.000000
82    1.420805   -3.108912   0.662145   -2.860557
83    1.150262   -2.819842   0.662145   -2.005315
84    0.785398   -2.356194   0.662145   -1.136277
85    0.261800   -2.202711   0.662145   -0.281035
86    1.277953   -3.006341   0.615479   -2.456873
87    0.523599   -2.255517   0.615479   -0.684719
88    1.374446    3.141593   0.589048    3.141593
89    0.942289   -2.634483   0.589048   -1.570796
90    0.196350    3.141593   0.589048    0.000000
91    1.220213   -3.063418   0.472749   -2.710826
92    1.068004   -2.895807   0.472749   -2.096192
93    0.680547   -2.563381   0.472749   -1.045400
94    0.413656   -2.633539   0.472749   -0.430767
95    1.178097    3.141593   0.392699    3.141593
96    0.858886   -2.776103   0.392699   -1.570796
97    0.392700    3.141593   0.392699    0.000000
98    1.002667   -3.011773   0.284924   -2.376765
99    0.610885   -2.847762   0.284924   -0.764828
100   0.981747    3.141593   0.196349    3.141593
101   0.804433   -2.948923   0.196349   -1.570796
102   0.589049    3.141593   0.196349    0.000000
103   0.785398    3.141593   0.000000    0.000000
104   0.196350    0.000000   1.374447    0.000000
105   1.308097   -1.978257   1.370707   -0.764828
106   0.274014   -0.805969   1.370707   -0.041141
107   1.099841   -1.664856   1.360401   -0.430767
108   0.445325   -1.186283   1.360401   -0.094629
109   0.909333   -1.517790   1.357866   -0.281035
110   0.627477   -1.346601   1.357866   -0.153484
111   1.423821   -2.776103   1.178098   -1.570796
112   0.000001    0.000000   1.178098    0.000000
113   1.215585   -2.330104   1.154239   -1.045400
114   0.213063   -1.731576   1.154239   -0.094629
115   1.008179   -2.040271   1.150262   -0.684719
116   0.426124   -1.743148   1.150262   -0.201358
117   0.716526   -1.870756   1.130287   -0.403056
118   1.356554   -2.809910   0.981748   -1.570796
119   0.196349    3.141593   0.981748    0.000000
120   1.141555   -2.514072   0.955317   -1.136277
121   0.318893   -2.427600   0.955317   -0.153484
122   0.877978   -2.379174   0.876815   -0.764684
123   0.581425   -2.341698   0.876815   -0.403056
124   1.296782   -2.856669   0.785398   -1.570796
125   0.392699    3.141593   0.785398    0.000000
126   1.464020   -3.065729   0.662145   -2.005315
127   1.050598   -2.724561   0.662145   -1.136277
128   0.582273   -2.807012   0.662145   -0.281035
129   0.809816   -2.701648   0.615479   -0.684719
130   1.246977   -2.915406   0.589048   -1.570796
131   0.589049    3.141593   0.589048    0.000000
132   1.422492   -3.075442   0.472749   -2.096192
133   1.007874   -2.858633   0.472749   -1.045400
134   0.774850   -2.921715   0.472749   -0.430767
135   1.209429   -2.984388   0.392699   -1.570796
136   0.785399    3.141593   0.392699    0.000000
137   1.386121   -3.103699   0.284924   -2.376765
138   0.987375   -3.007326   0.284924   -0.764828
139   1.374447    3.141593   0.196349    3.141593
140   1.186043   -3.060959   0.196349   -1.570796
141   0.981748    3.141593   0.196349    0.000000
142   1.178097    3.141593   0.000000    0.000000
143   0.196349    3.141593   1.374447    0.000000
144   1.370707   -2.376765   1.370707   -0.764828
145   0.284924   -2.376765   1.370707   -0.041141
146   1.154239   -2.096192   1.360401   -0.430767
147   0.472749   -2.096192   1.360401   -0.094629
148   0.955317   -2.005315   1.357866   -0.281035
149   0.662145   -2.005315   1.357866   -0.153484
150   0.392699    3.141593   1.178098    0.000000
151   1.360401   -2.710826   1.154239   -1.045400
152   0.472749   -2.710826   1.154239   -0.094629
153   1.150262   -2.456873   1.150262   -0.684719
154   0.615479   -2.456873   1.150262   -0.201358
155   0.876815   -2.376909   1.130287   -0.403056
156   0.589048    3.141593   0.981748    0.000000
157   1.357866   -2.860557   0.955317   -1.136277
158   0.662145   -2.860557   0.955317   -0.153484
159   1.130287   -2.738536   0.876815   -0.764684
160   0.876815   -2.738536   0.876815   -0.403056
161   0.785398    3.141593   0.785398    0.000000
162   1.357866   -2.988109   0.662145   -1.136277
163   0.955317   -2.988109   0.662145   -0.281035
164   1.150262   -2.940235   0.615479   -0.684719
165   0.981748    3.141593   0.589048    0.000000
166   1.360401   -3.046963   0.472749   -1.045400
167   1.154239   -3.046963   0.472749   -0.430767
168   1.178098    3.141593   0.392699    0.000000
169   1.370707   -3.100452   0.284924   -0.764828
170   1.374447    3.141593   0.196349    0.000000
171   0.589048    3.141593   1.374447    0.000000
172   1.463021   -2.763231   1.370707   -0.764828
173   0.625671   -2.857075   1.370707   -0.041141
174   1.272499   -2.496589   1.360401   -0.430767
175   0.733813   -2.628785   1.360401   -0.094629
176   1.101194   -2.432726   1.357866   -0.281035
177   0.866142   -2.496546   1.357866   -0.153484
178   0.785398    3.141593   1.178098    0.000000
179   0.839669   -2.948852   1.154239   -0.094629
180   1.348016   -2.815465   1.150262   -0.684719
181   0.929649   -2.801295   1.150262   -0.201358
182   1.128806   -2.736898   1.130287   -0.403056
183   0.981747    3.141593   0.981748    0.000000
184   1.038285   -3.013835   0.955317   -0.153484
185   1.421060   -3.015733   0.876815   -0.764684
186   1.217332   -2.966775   0.876815   -0.403056
187   1.178097    3.141593   0.785398    0.000000
188   1.337086   -3.090099   0.662145   -0.281035
189   1.374447    3.141593   0.589048    0.000000
190   0.981747    3.141593   1.374447    0.000000
191   1.002667   -3.011773   1.370707   -0.041141
192   1.431921   -2.864769   1.360401   -0.430767
193   1.068004   -2.895807   1.360401   -0.094629
194   1.308997   -2.790714   1.357866   -0.281035
195   1.150262   -2.819842   1.357866   -0.153484
196   1.178097    3.141593   1.178098    0.000000
197   1.220213   -3.063418   1.154239   -0.094629
198   1.277953   -3.006341   1.150262   -0.201358
199   1.419463   -3.014362   1.130287   -0.403056
200   1.374446    3.141593   0.981748    0.000000
201   1.420805   -3.108912   0.955317   -0.153484
202   1.374447    3.141593   1.374447    0.000000
203   1.386121   -3.103699   1.370707   -0.041141
204   1.422492   -3.075442   1.360401   -0.094629
205   1.464020   -3.065729   1.357866   -0.153484



</pre><router-link to="/repository/CURET"> [Return to Database Home Page] </router-link>

    </div>
    </div>
</template>

<script>
export default {

}
</script>

<style>
.container{
    width:70%;
    margin-left:auto;
    margin-right:auto;
    text-align: left;
	font-size: 18px;
}
.table{
        margin:auto;
}
</style>